import { Box, Typography, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import gifg from "../../assets/icon/7efs.gif";
import event from "../../assets/img/event.png";
import dateIcon from "../../assets/icon/date.svg";
import clock from "../../assets/icon/time.svg";
import guest from "../../assets/icon/guest.svg";
import location from "../../assets/icon/location.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import giffy from "../../assets/icon/giphy.gif";
import "add-to-calendar-button";
import { verifyBooking } from "../../api";
import Lottie from "react-lottie";
import anime from "../../assets/anime/uKGMJQo3HR.json";

const Booked = () => {
  const history = useNavigate();
  const [data, setData] = useState("");
  const handleGoBackTwice = () => {
    // Go back twice in the history stack
    history(-2);
  };

  const location = useLocation();
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };
  const reference = getQueryParam("reference");

  const handleVerifyBooking = async () => {
    setIsLoading(true);
    await verifyBooking(reference)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setData(res?.data?.result[0]);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    handleVerifyBooking()
  }, [reference]);

  const [isloading, setIsLoading] = useState(false);

  function formatDate(inputDateStr) {
    const inputDate = new Date(inputDateStr);

    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1; // Month index starts from 0
    const day = inputDate.getDate();

    const formattedMonth = String(month).padStart(2, "0");
    const formattedDay = String(day).padStart(2, "0");

    // Construct the output date string in the format "YYYY-MM-DD"
    const outputDateStr = `${year}-${formattedMonth}-${formattedDay}`;

    return outputDateStr;
  }
  const inputDateStr = data?.Date;
  const formattedDate = formatDate(inputDateStr);

  return (
    <>
      {isloading ? (
        <>
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <img src={giffy} width={140} />
          </Box>
        </>
      ) : (
        <>
          {!data || data?.length === 0 ? (
            <>
              <Box
                sx={{
                  bgcolor: "#eee",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Lottie options={defaultOptions} width={200} height={200} />
                <Typography sx={{ fontWeight: 500, fontSize: "30px" }}>
                 Booking Not Found
                </Typography>

                <a href="https://www.reisty.com/">
                  <Typography
                    color="primary"
                    sx={{
                      textDecoration: "underline",
                      fontSize: "12px",
                      mt: 1,
                    }}
                  >
                    Explore available events
                  </Typography>
                </a>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ backgroundColor: "rgba(188, 23, 47, 0.05)" }}>
                <Box
                  sx={{
                    width: {
                      xl: "30%",
                      lg: "40%",
                      md: "50%",
                      sm: "65%",
                      xs: "100%",
                    },
                    margin: "0 auto",
                    backgroundColor: "#fff",
                  }}
                >
                  <Box sx={{ mt: 2, minHeight: "1100px", pb: 4 }}>
                    <Box sx={{ bgcolor: "#fff", border: "1px solid #dadada" }}>
                      <Box
                        sx={{
                          borderBottom: "1px solid #dadada",
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={gifg} width={150} />
                        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
                          Booking Confirmed
                        </Typography>
                        <Typography
                          sx={{ fontSize: "13px", textAlign: "center" }}
                        >
                          Please check your inbox for a confirmation email and
                          your ticket.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: { md: "column", xs: "column" },
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            width: { md: "100%", xs: "100%" },
                            border: "1px solid #dadada",
                            p: 2,
                            display: "flex",
                            columnGap: 3,
                            boxSizing: "border-box",
                          }}
                        >
                          <img
                            src={data?.RestaurantDetails?.ImageUrl}
                            width={100}
                          />
                          <Box>
                            <Typography sx={{ fontWeight: 500 }}>
                              {data?.RestaurantDetails?.RestaurantName} -{" "}
                              {data?.EventName}
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={2}
                              align="center"
                              sx={{ mt: 1 }}
                            >
                              <img src={dateIcon} width={10} />
                              <Typography sx={{ fontSize: "10px" }}>
                                {data?.Date}
                              </Typography>
                            </Stack>
                            {/* <Stack
                  direction="row"
                  spacing={2}
                  align="center"
                  sx={{ mt: 0.5 }}
                >
                  <img src={location} width={15} />
                  <Typography sx={{ fontSize: "12px" }}>
                    TerraKulture, Nigeria
                  </Typography>
                </Stack> */}
                            <Stack
                              direction="row"
                              spacing={2}
                              align="center"
                              sx={{ mt: 0.5 }}
                            >
                              <img src={clock} width={14} />
                              <Typography sx={{ fontSize: "10px" }}>
                                {data?.Time}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={2}
                              align="center"
                              sx={{ mt: 0.5 }}
                            >
                              <img src={guest} width={14} />
                              <Typography sx={{ fontSize: "10px" }}>
                                {data?.NumberOfGuest}
                              </Typography>
                            </Stack>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            width: { md: "100%", xs: "100%" },
                            border: "1px solid #dadada",
                            p: 2,
                            boxSizing: "border-box",
                  
                          }}
                        >
                          {/* <AddToCalendar   event={{
    startDate: formattedDate,
    endDate: formattedDate,
    summary: `${data?.EventName} Event`,
    description: 'Discuss project details',
    location: data?.RestaurantDetails?.
    Address
    ,
  }}/> */}
                          {formattedDate && (
                            <add-to-calendar-button
                              name={`${data?.EventName} Event`}
                              options="'Apple','Google', 'iCal','Outlook.com','Microsoft 365'"
                              location={data?.RestaurantDetails?.Address}
                              startDate={formattedDate}
                              endDate={formattedDate}
                              startTime={data.Time}
                              endTime={data?.Time}
                              timeZone="currentBrowser"
                            ></add-to-calendar-button>
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        mt: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: { md: "row", xs: "column" },
                      }}
                    >
                      <Box
                        sx={{
                          width: { md: "100%", xs: "100%" },
                          boxSizing: "border-box",
                          mt: { md: 0, xs: 2 },
                          mx:2
                        }}
                      >
                        <Box sx={{ border: "1px solid #dadada" }}>
                          <Box sx={{ height: "0px" }}></Box>
                          <Box sx={{ bgcolor: "#fff", p: 2 }}>
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: 500 }}
                            >
                              {data?.RestaurantDetails?.RestaurantName}
                            </Typography>
                            <Typography sx={{ fontSize: "13px", mt: 2 }}>
                              <span style={{ textDecoration: "underline" }}>
                                Address:
                              </span>{" "}
                              {data?.RestaurantDetails?.Address}
                            </Typography>
                            <a
                              href={data?.RestaurantDetails?.Website}
                              target="_blank"
                            >
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#BC172F",
                                  textDecoration: "underline",
                                }}
                              >
                                {" "}
                                {data?.RestaurantDetails?.Website}
                              </Typography>
                            </a>
                            <a
                              href={`tel:${data?.RestaurantDetails?.PhoneNumber}`}
                            >
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 2,
                                  color: "#BC172F",
                                  textDecoration: "underline",
                                }}
                              >
                                {" "}
                                {data?.RestaurantDetails?.PhoneNumber}
                              </Typography>
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box align="center" sx={{ mt: 6 }}>
                      <Button
                        onClick={handleGoBackTwice}
                        size="large"
                        variant="contained"
                        sx={{ px: 8, py: 2, borderRadius: 0 }}
                      >
                        Back to home
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Booked;
