import React, { useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  Modal,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../Component/common/CustomInput";
import card from "../../assets/icon/card.svg";
import pay from "../../assets/icon/pay.svg";
import dateIcon from "../../assets/icon/date.svg";
import clock from "../../assets/icon/time.svg";
import guest from "../../assets/icon/guest.svg";
import { getRestaurantEvent, getSingleTicket } from "../../api";
import { usePaystackPayment } from "react-paystack";
import { PaystackButton } from "react-paystack";
import { sendBooking } from "../../api";
import { TiMinus } from "react-icons/ti";
import { TiPlus } from "react-icons/ti";
import { TfiRuler } from "react-icons/tfi";

function generateUniqueCode(prefix = "web_") {
  const timestamp = Date.now().toString();

  const randomChars = Math.random().toString(36).substring(2, 6).toUpperCase();

  return `${prefix}${timestamp}${randomChars}`;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 350, xs: 300 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
};

const onSuccess = (reference) => {
  console.log(reference);
};

// you can call this function anything
const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log("closed");
};

const Booking = ({ event_id, setStep, time }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id, name } = useParams();
  const navigate = useNavigate();

  const [count, setCount] = useState(1);
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(1000);
  const [totalPrice, setTotalPrice] = useState(1000);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [referalCode, setReferalCode] = useState("");

  useEffect(() => {
    setTotalPrice(amount * count);
  }, [count]);
  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setError(!validateEmail(value)); // Set error if email is not valid
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: totalPrice * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_CODE,
    // publicKey: "pk_test_6c0b3d295bf7fd5d570b36fdfde7a6c5efcc9a73",
    firstName: firstName,
    lastName: lastName,
  };

  const initializePayment = usePaystackPayment(config);

  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmError, setConfirmError] = useState(false);

  useEffect(() => {
    if (email === confirmEmail) {
      setConfirmError(false);
    } else setConfirmError(true);
  }, [confirmEmail, email]);

  const handleAdd = () => {
    setCount((count) => count + 1);
  };
  const handleSub = () => {
    if (count > 1) {
      setCount((count) => count - 1);
    }
  };

  useEffect(() => {
    setTotalPrice(amount * count);
  }, [count]);

  const handlegetSingleTicket = async () => {
    setLoading(true);
    await getSingleTicket(event_id)
      .then((res) => {
        setLoading(false);
        setData(res.data.result);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    handlegetSingleTicket();
  }, [id]);

  useEffect(() => {
    setAmount(parseInt(data?.TicketPrice));
    setTotalPrice(parseInt(data?.TicketPrice));
  }, [data]);

  const handlePaystackSuccessAction = (reference) => {
    handleSendBooking(reference?.reference);
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Make Payment",
    className: `${
      !firstName || !lastName || !email || !phone || confirmError || error
        ? "paystack-button-disabled"
        : "paystack-button"
    }`,
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const [errorMessage, setErrorMessage] = useState("");

  const handleSendBooking = async () => {
    const ref = generateUniqueCode();
    setOpen(false);
    setIsLoading(true);
    await sendBooking(
      data?.TicketId,
      count,
      firstName,
      lastName,
      email,
      phone,
      referalCode,
      totalPrice * 100,
      "Card",
      ref,
      time
    )
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        if (res?.data?.status) {
          window.location.href = res?.data?.result?.authorization_url;
        } else {
          setIsLoading(false);
          setErrorMessage(res?.data?.error_message);
          setOpen(true);
        }
        console.log(res);
      })
      .catch((err) => {
        setOpen(true);
        setIsLoading(false);
        setErrorMessage(err?.message);
        console.log(err);
      });
  };

  return (
    <>
      <Button
        onClick={() => setStep()}
        sx={{ mt: 2, display: "flex", alignItems: "center", columnGap: 3 }}
      >
        <IoIosArrowBack style={{}} />
        <Typography sx={{ fontWeight: 500, fontSize: "13px" }}>
          Back to Ticket
        </Typography>
      </Button>

      <Box sx={{ mt: 3, minHeight: "1100px", px: 2 }}>
        <Grid
          container
          spacing={4}
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse" },
          }}
        >
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  bgcolor: "#fff",
                  p: 2,
                  py: 3,
                  border: "1px solid #DADADA",
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <Typography
                  sx={{ color: "#2B2B2B", fontWeight: 700, fontSize: "13px" }}
                >
                  Contact Details
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#fff",
                  p: 2,
                  pb: 4,
                  border: "1px solid #DADADA",
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <CustomInput
                      label="First Name *"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomInput
                      label="Last Name *"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomInput
                      label="Email Address *"
                      placeholder="Email Address"
                      value={email}
                      error={error}
                      helperText={error ? "Email must be valid" : ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomInput
                      label="Confirm Email Address *"
                      placeholder="Email Address"
                      value={confirmEmail}
                      error={confirmError}
                      helperText={confirmError ? "Emails do not match" : ""}
                      onChange={(e) => setConfirmEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomInput
                      label="Phone Number *"
                      placeholder="Phone Number "
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomInput
                      label="Referral Code"
                      placeholder="#SAMMYFXX "
                      value={referalCode}
                      onChange={(e) => setReferalCode(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
              {/* <Box sx={{ mt: 3, border: "1px solid #dadada", bgcolor: "#fff" }}>
                <Box sx={{ px: 2, py: 4, borderBottom: "1px solid #dadada" }}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    Payment Method{" "}
                  </Typography>
                </Box>
                <Box sx={{ p: 0 }}>
                  <MenuItem
                    sx={{
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #dadada",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: 1,
                        alignItems: "center",
                      }}
                    >
                      <img src={pay} />
                      <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                        Pay Online
                      </Typography>
                    </Box>
                    <Radio />
                  </MenuItem>
                  <MenuItem
                    sx={{
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: 1,
                        alignItems: "center",
                      }}
                    >
                      <img src={card} width={20} />
                      <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                        Pay with Card
                      </Typography>
                    </Box>
                    <Radio />
                  </MenuItem>
                </Box>
              </Box> */}

              <Box sx={{ my: 4 }}>
                <Box sx={{ display: "flex", columnGap: 1 }}>
                  <Checkbox />
                  <Typography sx={{ fontSize: "13px" }}>
                    By Clicking on the Complete button You have read and agree
                    to the{" "}
                    <span style={{ color: "#BC172F" }}>Terms & Conditions</span>{" "}
                    and <span style={{ color: "#BC172F" }}>Privacy Policy</span>
                  </Typography>
                </Box>

                <Button
                  disabled={!firstName || !lastName || !email || !phone}
                  onClick={() => {
                    handleSendBooking();
                  }}
                  variant="contained"
                  fullWidth
                  sx={{ mt: 3, borderRadius: "10px", fontWeight: 500, py: 2 }}
                >
                  Make Payment
                </Button>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item md={1}></Grid> */}
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  bgcolor: "#fff",
                  border: "1px solid #dadada",
                  borderRadius: "8px",
                }}
              >
                <Box sx={{ p: 2, py: 3, borderBottom: "1px solid #dadada" }}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {name} - Event Details
                  </Typography>
                </Box>
                <Box sx={{ p: 2, pb: 5 }}>
                  <Stack direction="row" spacing={2} align="center">
                    <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
                      Description:
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      {data?.Description}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    align="center"
                    sx={{ mt: 2 }}
                  >
                    <img src={guest} />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IconButton
                        onClick={handleSub}
                        sx={{
                          borderRadius: 1,
                          border: "0.5px solid #1a1a1a",
                          fontSize: "14px",
                          fontWeight: 900,
                        }}
                        disabled={loading}
                      >
                        <TiMinus />
                      </IconButton>
                      <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                        {count} {count === 1 ? "Ticket" : "Tickets"}
                      </Typography>
                      <IconButton
                        disabled={loading}
                        onClick={handleAdd}
                        sx={{
                          borderRadius: 1,
                          border: "0.5px solid #1a1a1a",
                          fontSize: "14px",
                          fontWeight: 900,
                        }}
                      >
                        <TiPlus />
                      </IconButton>
                    </Box>
                  </Stack>
                  {/* <Stack
                    direction="row"
                    spacing={2}
                    align="center"
                    sx={{ mt: 2 }}
                  >
                    <img src={clock} />
                    <Typography sx={{ fontSize: "13px" }}>6:00 PM</Typography>
                  </Stack> */}
                </Box>
              </Box>
              <Box
                sx={{
                  bgcolor: "#fff",
                  border: "1px solid #dadada",
                  mt: 5,
                  borderRadius: "8px",
                }}
              >
                <Box sx={{ p: 2, py: 2, borderBottom: "1px solid #dadada" }}>
                  <Typography
                    sx={{ fontSize: "13px", fontWeight: 500, color: "#BC172F" }}
                  >
                    Order Summary
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: 2,
                    py: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #dadada",
                  }}
                >
                  {loading ? (
                    <Skeleton variant="sqare" width="100%" />
                  ) : (
                    <Typography sx={{ fontSize: "13px" }}>
                      {name} - <b>{data?.TicketName} ticket</b>
                    </Typography>
                  )}
                </Box>
                <Box sx={{ p: 1 }}>
                  <Box
                    sx={{
                      p: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      Total
                    </Typography>
                    {loading ? (
                      <Skeleton variant="sqare" width="30%" />
                    ) : (
                      <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                        ₦{totalPrice.toLocaleString()}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      p: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      Amount Due
                    </Typography>
                    {loading ? (
                      <Skeleton variant="sqare" width="30%" />
                    ) : (
                      <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                        ₦{totalPrice.toLocaleString()}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  columnGap: 2,
                  width: { md: "60%", xs: "100%" },
                }}
              >
                <Checkbox checked />
                <Typography sx={{ fontSize: "13px" }}>
                  Get notifications of offers and other relevant emails from
                  this restaurant
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  columnGap: 2,
                  width: { md: "60%", xs: "100%" },
                }}
              >
                <Checkbox />
                <Typography sx={{ fontSize: "13px" }}>
                  I would like to receive news and updates from this business.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isloading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ bgcolor: "red", p: 2 }}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }}>
              Error Message
            </Typography>
          </Box>
          <Box sx={{ height: "70px", p: 2 }}>
            <Typography color="error">{errorMessage}</Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Booking;
